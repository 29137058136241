<template>
  <Card>
    <template #title>
      <h3>{{ _t('label_Media_calls') }}</h3>
      <Dropdown
          v-model="selectedPeriod"
          :options="getPeriodsMediaCallsByTypes"
          class="p-inputtext-sm"
          optionLabel="label"
          optionValue="key"
          placeholder="Select a Period"
      />
    </template>
    <template #content>
      <Chart :data="data" :height="300" :options="options" type="pie"/>
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Alarm Status</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.alarmstatus >= 0">{{ getItemsMediaCallsByTypes.alarmstatus }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">AVS</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.avs >= 0">{{ getItemsMediaCallsByTypes.avs }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">DMLS</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.dmls >= 0">{{ getItemsMediaCallsByTypes.dmls }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Email</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.email >= 0">{{ getItemsMediaCallsByTypes.email }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Email Forward</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.emailForward >= 0">{{ getItemsMediaCallsByTypes.emailForward }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Gets Pager</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.getsPager >= 0">{{ getItemsMediaCallsByTypes.getsPager }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Mobile App</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.mobileApp >= 0">{{ getItemsMediaCallsByTypes.mobileApp }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Modbus</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.modbus >= 0">{{ getItemsMediaCallsByTypes.modbus }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">MQTT</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.mqtt >= 0">{{ getItemsMediaCallsByTypes.mqtt }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Popup</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.popup >= 0">{{ getItemsMediaCallsByTypes.popup }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Portal</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.portal >= 0">{{ getItemsMediaCallsByTypes.portal }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Signal App</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.signalApp >= 0">{{ getItemsMediaCallsByTypes.signalApp }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Spectralink</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.spectralink >= 0">{{ getItemsMediaCallsByTypes.spectralink }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Telepage</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.telepage >= 0">{{ getItemsMediaCallsByTypes.telepage }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Telephone</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.telephone >= 0">{{ getItemsMediaCallsByTypes.telephone }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Threema</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.threema >= 0">{{ getItemsMediaCallsByTypes.threema }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Web</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.web >= 0">{{ getItemsMediaCallsByTypes.web }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Web Sms</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.websms >= 0">{{ getItemsMediaCallsByTypes.websms }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--         <div class="p-d-inline-flex p-text-bold">Windows Popup</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsMediaCallsByTypes && getItemsMediaCallsByTypes.windowsPopup >= 0">{{ getItemsMediaCallsByTypes.windowsPopup }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->

    </template>
  </Card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {MediaCallsPeriodEnum} from "../../../enums/media-calls-period.enum";

export default {
  name: "media-calls-by-types",
  title: 'label_Media_calls',
  preview: 'assets/layout/images/widgets/media-calls-by-types.png',
  configuration: {},
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          },
          position: 'bottom',
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    },
  }),
  mounted() {
    try {
      const json = JSON.parse(localStorage.getItem('dashboard'));
      this.setPeriodMediaCallsByTypes(json.mediaCallsByTypes ?? MediaCallsPeriodEnum.TODAY);
    } catch (e) {
      this.setPeriodMediaCallsByTypes(MediaCallsPeriodEnum.TODAY);
    }
    this.callMediaCallsByTypes();
    this.setPeriodsMediaCallsByTypes([
      {key: MediaCallsPeriodEnum.TODAY, label: this._t('label_Today')},
      {key: MediaCallsPeriodEnum.WEEK_CURRENT, label: this._t('label_Current_week')},
      {key: MediaCallsPeriodEnum.WEEK_AGO, label: this._t('label_Last_7_days')},
      {key: MediaCallsPeriodEnum.MONTH_CURRENT, label: this._t('label_Current_month')},
      {key: MediaCallsPeriodEnum.MONTH_PREVIOUS, label: this._t('last_month')},
      {key: MediaCallsPeriodEnum.MONTH_AGO, label: this._t('label_Last_30_days')}
    ])
  },
  methods: {
    ...mapActions({
      'callMediaCallsByTypes': 'dashboard/callMediaCallsByTypes',
    }),
    ...mapMutations({
      'setPeriodMediaCallsByTypes': 'dashboard/setPeriodMediaCallsByTypes',
      'setPeriodsMediaCallsByTypes': 'dashboard/setPeriodsMediaCallsByTypes',
    }),
  },
  computed: {
    ...mapGetters({
      'getItemsMediaCallsByTypes': 'dashboard/getItemsMediaCallsByTypes',
      'getPeriodMediaCallsByTypes': 'dashboard/getPeriodMediaCallsByTypes',
      'getPeriodsMediaCallsByTypes': 'dashboard/getPeriodsMediaCallsByTypes',
    }),
    selectedPeriod: {
      get: function () {
        return this.getPeriodMediaCallsByTypes;
      },
      set: function (val) {
        try {
          const json = JSON.parse(localStorage.getItem('dashboard'));
          localStorage.setItem('dashboard', JSON.stringify({...json, mediaCallsByTypes: val}));
        } catch (e) {
          localStorage.setItem('dashboard', JSON.stringify({mediaCallsByTypes: val}));
        }
        this.setPeriodMediaCallsByTypes(val);
        this.callMediaCallsByTypes(true);
      },
    },
    data: function () {
      const labels = [];
      const data = [];
      if (this.getItemsMediaCallsByTypes) {
        Object.keys(this.getItemsMediaCallsByTypes).sort().forEach(key => {
          labels.push(this._t(key));
          data.push(this.getItemsMediaCallsByTypes[key]);
        });
      }
      return {
        labels,
        datasets: [
          {
            backgroundColor: [
              '#3996f3',
              '#314c5d',
              '#fad165',
              '#85b44a',
              '#8c5cfd',
              '#46c1d3',
              '#e23e57',
              '#989898',
              '#1b499e',
              '#f5856d',
            ],
            data,
          },
        ]
      };
    },
  },
}
</script>

<style scoped>

</style>
