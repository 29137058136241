<template>
  <Card>
    <template #title>
      <h3>{{ _t('label_Last_10_alarms') }}</h3>
    </template>
    <template #content>
      <DataTable :value="getAlarms" class="p-datatable-sm" responsiveLayout="scroll">
        <Column :header="_t('label_status')" field="alarmJobStatus">
          <template #body="{ data }">
            <div class="p-d-flex" style="width: 12rem">
              <div class="p-d-inline-flex p-mr-2" style="width:2rem;">
                <Button :style="'right:20px; border: none; border-radius: 5px; margin:2px; display: inline-block; background-color: ' +
                          associateColor(data.jobStatusColor)"
                        type="button"
                />
              </div>
              <div class="p-d-inline-flex p-mt-3">
                <strong class="truncate">
                  {{ _t(data.alarmJobStatus) }}
                </strong>
              </div>
            </div>
          </template>
        </Column>
        <Column field="alarmPointStatus" header="">
          <template #body="{ data }">
            <div class="p-d-flex" style="width: 12rem">
              <div class="p-d-inline-flex p-mt-3">
                {{ _t(data.alarmPointStatus) }}
              </div>
            </div>
          </template>
        </Column>
        <Column :header="_t('label_Alarm_name')" field="alarmPointName"></Column>
        <Column :header="_t('filter_triggeredby')" field="triggeredBy"></Column>
        <Column :header="_t('label_alarm_id')" field="alarmPointId"></Column>
        <Column :header="_t('label_started')" field="alarmJobStartDate"></Column>
        <Column :header="_t('label_ended')" field="alarmJobEndDate"></Column>
        <Column :header="_t('label_system_id')" field="alarmPointSysId"></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {associateColor} from "@/service/helper/styleHelper";

export default {
  name: "last-alarms",
  title: 'label_Last_10_alarms',
  preview: 'assets/layout/images/widgets/last10alarms.png',
  isLoading: false,
  configuration: {},
  mounted() {
    this.callAlarms();
  },
  methods: {
    ...mapActions({
      'callAlarms': 'alarmTable/callAlarms',
    }),
    associateColor,
  },
  computed: {
    ...mapGetters({
      'getAlarms': 'alarmTable/getAlarms',
    }),
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 0.95rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 2px;
    font-weight: 1500;
    font-size: 0.95rem;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
