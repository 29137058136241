<template>
  <Card>
    <template #title>
      <h3>{{ _t('ALARM_STATISTIC') }}</h3>
      <Dropdown
          v-model="selectedPeriod"
          :options="getPeriodsStatistics"
          class="p-inputtext-sm"
          optionLabel="label"
          optionValue="key"
          placeholder="Select a Period"
      />
    </template>
    <template #content>
      <Chart v-if="getItemsStatistics" :data="data" :height="300" :options="options" type="bar"/>
      <Skeleton v-else height="150px" width="100%"/>
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--        <div class="p-d-inline-flex p-text-bold">All alarms</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsStatistics && getItemsStatistics.allAlarmsCount >= 0">{{ getItemsStatistics.allAlarmsCount }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--        <div class="p-d-inline-flex p-text-bold">Calls</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsStatistics && getItemsStatistics.callsCount >= 0">{{ getItemsStatistics.callsCount }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--        <div class="p-d-inline-flex p-text-bold">Failed alarms</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsStatistics && getItemsStatistics.failedAlarmsCount >= 0">{{ getItemsStatistics.failedAlarmsCount }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--        <div class="p-d-inline-flex p-text-bold">Success alarms</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsStatistics && getItemsStatistics.successAlarmsCount >= 0">{{ getItemsStatistics.successAlarmsCount }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->
      <!--      <div class="p-d-flex p-flex-row p-jc-between">-->
      <!--        <div class="p-d-inline-flex p-text-bold">Unknown alarms</div>-->
      <!--        <div class="p-d-inline-flex" v-if="getItemsStatistics && getItemsStatistics.unknownAlarmsCount >= 0">{{ getItemsStatistics.unknownAlarmsCount }}</div>-->
      <!--        <Skeleton class="p-d-inline-flex" v-else />-->
      <!--      </div>-->

    </template>
  </Card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {MediaCallsPeriodEnum} from "../../../enums/media-calls-period.enum";

export default {
  name: "statistics",
  title: 'ALARM_STATISTIC',
  preview: 'assets/layout/images/widgets/statistics.png',
  configuration: {},
  data: () => ({
    colors: [
      '#3996f3',
      '#314c5d',
      '#fad165',
      '#85b44a',
      '#8c5cfd',
      '#46c1d3',
      '#e23e57',
      '#989898',
      '#1b499e',
      '#f5856d',
    ],
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          },
          position: 'bottom',
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    },
  }),
  mounted() {
    try {
      const json = JSON.parse(localStorage.getItem('dashboard'));
      this.setPeriodStatistics(json.statistics ?? MediaCallsPeriodEnum.TODAY);
    } catch (e) {
      this.setPeriodStatistics(MediaCallsPeriodEnum.TODAY);
    }
    this.callStatistics();

    this.setPeriodsStatistics([
      {key: MediaCallsPeriodEnum.TODAY, label: this._t('label_Today')},
      {key: MediaCallsPeriodEnum.WEEK_CURRENT, label: this._t('label_Current_week')},
      {key: MediaCallsPeriodEnum.WEEK_AGO, label: this._t('label_Last_7_days')},
      {key: MediaCallsPeriodEnum.MONTH_CURRENT, label: this._t('label_Current_month')},
      {key: MediaCallsPeriodEnum.MONTH_PREVIOUS, label: this._t('last_month')},
      {key: MediaCallsPeriodEnum.MONTH_AGO, label: this._t('label_Last_30_days')}
    ])
  },
  methods: {
    ...mapActions({
      'callStatistics': 'dashboard/callStatistics',
    }),
    ...mapMutations({
      'setPeriodStatistics': 'dashboard/setPeriodStatistics',
      'setPeriodsStatistics': 'dashboard/setPeriodsStatistics',
    }),
  },
  computed: {
    ...mapGetters({
      'getItemsStatistics': 'dashboard/getItemsStatistics',
      'getPeriodStatistics': 'dashboard/getPeriodStatistics',
      'getPeriodsStatistics': 'dashboard/getPeriodsStatistics',
    }),
    selectedPeriod: {
      get: function () {
        return this.getPeriodStatistics;
      },
      set: function (val) {
        try {
          const json = JSON.parse(localStorage.getItem('dashboard'));
          localStorage.setItem('dashboard', JSON.stringify({...json, statistics: val}));
        } catch (e) {
          localStorage.setItem('dashboard', JSON.stringify({statistics: val}));
        }
        this.setPeriodStatistics(val);
        this.callStatistics(true);
      },
    },
    data: function () {
      const labels = [];
      const datasets = [];
      const data = [];
      if (this.getItemsStatistics) {
        let colorIndex = 0;
        Object.keys(this.getItemsStatistics).sort().forEach(key => {
          labels.push(this._t(key));
          let color = null;
          if (this.colors[colorIndex]) {
            color = this.colors[colorIndex];
          } else {
            colorIndex = 0
            color = this.colors[colorIndex];
          }
          datasets.push({
            backgroundColor: color,
            data: [this.getItemsStatistics[key]],
            label: this._t(key),
          });
          data.push(this.getItemsStatistics[key]);
          colorIndex++;
        });
      }
      return {
        labels: [''],
        datasets,
      };
    },
  },
}
</script>

<style scoped>

</style>
