<template>
  <Card>
    <template #title>
      <h3>{{ _t('label_Media_calls') }}</h3>
      <Dropdown
          v-model="selectedPeriod"
          :options="getPeriodsMediaCalls"
          class="p-inputtext-sm"
          optionLabel="label"
          optionValue="key"
          placeholder="Select a Period"
      />
    </template>
    <template #content>
      <Chart v-if="getItemsMediaCalls" :data="data" :height="300" :options="options" type="bar"/>
      <Skeleton v-else height="150px" width="100%"/>
    </template>
  </Card>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {MediaCallsPeriodEnum} from "../../../enums/media-calls-period.enum";

export default {
  name: "media-calls",
  title: 'label_Media_calls',
  preview: 'assets/layout/images/widgets/media-calls.png',
  configuration: {},
  data: () => ({
    colors: [
      '#3996f3',
      '#314c5d',
      '#fad165',
      '#85b44a',
      '#8c5cfd',
      '#46c1d3',
      '#e23e57',
      '#989898',
      '#1b499e',
      '#f5856d',
    ],
    options: {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          },
          position: 'bottom',
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#495057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    },
  }),
  mounted() {
    try {
      const json = JSON.parse(localStorage.getItem('dashboard'));
      this.setPeriodMediaCalls(json.mediaCalls ?? MediaCallsPeriodEnum.TODAY);
    } catch (e) {
      this.setPeriodMediaCalls(MediaCallsPeriodEnum.TODAY);
    }
    this.callMediaCalls();
    this.setPeriodsMediaCalls([
      {key: MediaCallsPeriodEnum.TODAY, label: this._t('label_Today')},
      {key: MediaCallsPeriodEnum.WEEK_CURRENT, label: this._t('label_Current_week')},
      {key: MediaCallsPeriodEnum.WEEK_AGO, label: this._t('label_Last_7_days')},
      {key: MediaCallsPeriodEnum.MONTH_CURRENT, label: this._t('label_Current_month')},
      {key: MediaCallsPeriodEnum.MONTH_PREVIOUS, label: this._t('last_month')},
      {key: MediaCallsPeriodEnum.MONTH_AGO, label: this._t('label_Last_30_days')}
    ])
  },
  methods: {
    ...mapActions({
      'callMediaCalls': 'dashboard/callMediaCalls',
    }),
    ...mapMutations({
      'setPeriodMediaCalls': 'dashboard/setPeriodMediaCalls',
      'setPeriodsMediaCalls': 'dashboard/setPeriodsMediaCalls',
    }),
  },
  computed: {
    ...mapGetters({
      'getItemsMediaCalls': 'dashboard/getItemsMediaCalls',
      'getPeriodMediaCalls': 'dashboard/getPeriodMediaCalls',
      'getPeriodsMediaCalls': 'dashboard/getPeriodsMediaCalls',
    }),
    selectedPeriod: {
      get: function () {
        return this.getPeriodMediaCalls;
      },
      set: function (val) {
        try {
          const json = JSON.parse(localStorage.getItem('dashboard'));
          localStorage.setItem('dashboard', JSON.stringify({...json, mediaCalls: val}));
        } catch (e) {
          localStorage.setItem('dashboard', JSON.stringify({mediaCalls: val}));
        }
        this.setPeriodMediaCalls(val);
        this.callMediaCalls(true);
      },
    },
    data: function () {
      const labels = [];
      const datasets = [];
      const data = [];
      if (this.getItemsMediaCalls) {
        let colorIndex = 0;
        Object.keys(this.getItemsMediaCalls).sort().forEach(key => {
          labels.push(this._t(key));
          let color = null;
          if (this.colors[colorIndex]) {
            color = this.colors[colorIndex];
          } else {
            colorIndex = 0
            color = this.colors[colorIndex];
          }
          datasets.push({
            backgroundColor: color,
            data: [this.getItemsMediaCalls[key]],
            label: this._t(key),
          });
          data.push(this.getItemsMediaCalls[key]);
          colorIndex++;
        });
      }
      return {
        labels: [''],
        datasets,
      };
    },
  },
}
</script>

<style scoped>

</style>
